import { inject, NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from './auth/auth.guard';
import { NotFoundPageModule } from './not-found/not-found.module';
import { PokerComponent } from './poker/poker.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'drivers',
    loadChildren: () =>
      import('./drivers/drivers.module').then((m) => m.DriversPageModule),
    canActivate: [() => inject(AuthenticationGuard).canActivate()],
  },
  {
    path: 'transfers',
    loadChildren: () =>
      import('./transfers/pages/transfers/transfers.module').then(
        (m) => m.TransfersPageModule,
      ),
    canActivate: [() => inject(AuthenticationGuard).canActivate()],
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'tours',
    loadChildren: () =>
      import('./tours/pages/tours/tours.module').then((m) => m.ToursPageModule),
    canActivate: [() => inject(AuthenticationGuard).canActivate()],
  },
  {
    path: 'my-transfers',
    loadChildren: () =>
      import('./my-transfers/pages/my-transfers.module').then(
        (m) => m.MyTransfersPageModule,
      ),
    canActivate: [() => inject(AuthenticationGuard).canActivate()],
  },
  {
    path: 'billings',
    loadChildren: () =>
      import('./billings/pages/billings.module').then(
        (m) => m.BillingsPageModule,
      ),
    canActivate: [() => inject(AuthenticationGuard).canActivate()],
  },
  {
    path: 'agb',
    loadChildren: () => import('./agb/agb.module').then((m) => m.AgbPageModule),
  },
  {
    path: 'datenschutz',
    loadChildren: () =>
      import('./datenschutz/datenschutz.module').then(
        (m) => m.DatenschutzPageModule,
      ),
  },
  {
    path: 'impressum',
    loadChildren: () =>
      import('./impressum/impressum.module').then((m) => m.ImpressumPageModule),
  },
  {
    path: 'registration',
    loadChildren: () =>
      import('./registration/registration.module').then(
        (m) => m.RegistrationPageModule,
      ),
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./profile/profile.module').then((m) => m.ProfilePageModule),
    canActivate: [() => inject(AuthenticationGuard).canActivate()],
  },
  {
    path: 'company',
    loadChildren: () =>
      import('./company/company.module').then((m) => m.CompanyPageModule),
    canActivate: [() => inject(AuthenticationGuard).canActivate()],
  },
  {
    path: 'biddings',
    loadChildren: () =>
      import('./biddings/biddings.module').then((m) => m.BiddingsPageModule),
    canActivate: [() => inject(AuthenticationGuard).canActivate()],
  },
  {
    path: 'reports',
    loadChildren: () =>
      import('./reports/reports.module').then((m) => m.ReportsPageModule),
  },
  {
    path: 'trainings',
    loadChildren: () =>
      import('./trainings/pages/trainings/trainings.module').then(
        (m) => m.TrainingsPageModule,
      ),
  },
  {
    path: 'quality-scoring',
    loadChildren: () =>
      import('./trainings/pages/quality-scoring/quality-scoring.module').then(
        (m) => m.QualityScoringPageModule,
      ),
  },
  {
    path: 'vehicle-inspection',
    loadChildren: () =>
      import(
        './vehicle-inspection/pages/vehicle-inspection/vehicle-inspection.module'
      ).then((m) => m.VehicleInspectionPageModule),
  },
  {
    path: 'not-found',
    loadChildren: () =>
      import('./not-found/not-found.module').then((m) => m.NotFoundPageModule),
  },
  {
    path: 'poker',
    loadChildren: () =>
      import('./poker/poker.module').then((m) => m.PokerModule),
  },
  { path: '**', redirectTo: 'not-found', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
